<template>
  <v-timeline v-if="messages.length > 0">
    <template v-for="(msg, index) in messages">
      <v-timeline-item v-if="msg.visibleToStudent" :key="'message-' + index" :right="msg.username === appUsername" :left="msg.username !== appUsername">
        <span slot="opposite">{{ msg.name }} - {{ stringFormatDate(msg.date) }}</span>
        <v-card class="elevation-2">
          <v-card-text>{{ msg.message }}</v-card-text>
        </v-card>
      </v-timeline-item>
    </template>
  </v-timeline>
</template>
<script>
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  props: {
    messages: {
      type: Array,
      required: true
    },
    appUsername: {
      type: String,
      required: true
    }
  },
  setup () {
    return {
      stringFormatDate
    }
  }
}
</script>
